import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/admin/login',
        method: 'post',
        data
    })
}

export function userInfo() {
    return request({
        url: '/admin/info',
        method: 'get'
    })
}

export function getMpPathQrcode(data){
    return request({
        url: '/user/getMpPathQrcode',
        method: 'post',
        data: data
    })
}

export function loginMpPathQrcode(params){
    return request({
        url: '/user/loginMpPathQrcode',
        method: 'get',
        params: params
    })
}
