<template>
	<div class="insurance-login">
		<form @submit.prevent="login" class="login-form">
			<div class="form-control">
				<i class="el-icon-mobile-phone"></i>
				<input type="text" v-model="ruleForm.phone" placeholder="请输入手机号" />
			</div>
			<div class="form-control">
				<i class="el-icon-lock"></i>
				<input type="password" v-model="ruleForm.password" placeholder="请输入密码" />
			</div>
			<button class="submit-btn" type="submit">登录</button>
		</form>
		<!-- <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="login-ruleForm" size="large" status-icon>
			<el-form-item label="" prop="phone">
				<el-input v-model="ruleForm.phone" prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号" />
			</el-form-item>
			<el-form-item label="" prop="password">
				<el-input v-model="ruleForm.password" type="password" prefix-icon="el-icon-lock" placeholder="请输入密码" />
			</el-form-item>
			<el-form-item>
				<el-button class="main-btn" type="primary" @click="submitForm('ruleForm')">登录</el-button>
			</el-form-item>
		</el-form> -->
		<div class="link">
			<div @click="registerClick"><span>立即注册</span></div>
			<!-- <router-link to="/forgotPassword"><span>忘记密码</span></router-link> -->
		</div>
	</div>
</template>

<script>
	// import http from "@/http";
	import axios from 'axios';
	export default {
		data() {
			return {
				ruleForm: {
					phone: "",
					password: "",
				},
				rules: {
					phone: [{
							required: true,
							message: "请输入手机号",
							trigger: "blur"
						},
						{
							pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
							message: "请输入正确的手机号",
							trigger: "blur",
						},
					],
					password: [{
						required: true,
						message: "请输入密码",
						trigger: "blur"
					}],
				}
			}
		},
		methods: {
			login() {
				let Phone_reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
				if (!Phone_reg.test(this.ruleForm.phone)) {
					this.$message.error('请输入手机号')
				} else if(!this.ruleForm.password){
					this.$message.error('请输入密码')
				} else{
					axios.post('/api/user/login', {
							type: 1,
							phone: this.ruleForm.phone,
							password: this.ruleForm.password,
						})
						.then(res => {
							// 处理响应
							if (res.data.code == 0) {
                const insureUrl = process.env.VUE_APP_INSURE_URL;
                window.location.href = insureUrl + '?from=sso&token=' + res.data.data.token;
							} else {
								if (this.ruleForm.phone == 'test') {
									this.$message.error('测试账号已过期')
								} else {
									this.$message.error(res.data.desc);
								}
							}
						})
						.catch(error => {
							// 处理错误
							console.error(error);
						});
				}

			},
			registerClick() {
				const url = 'http://insure.kuaizhipei.com/register';
				window.open(url, '_blank');
			},
			// submitForm(formName) {
			// 	this.$refs[formName].validate((valid) => {
			// 		if (valid) {
			// 			http.fetchPost("/api/user/login", {
			// 				type: 1,
			// 				phone: this.ruleForm.phone,
			// 				password: this.ruleForm.password,
			// 			}).then((res) => {
			// 				if (res.data.code == 0) {
			// 					localStorage.setItem("token", res.data.data.token);
			// 					localStorage.setItem("name", res.data.data.name);
			// 					localStorage.setItem("phone", res.data.data.phone);
			// 					this.token = res.data.data.token;
			// 					this.userName = res.data.data.name;
			// 					// this.getInfo();
			// 					this.$message.success("登录成功");
			// 					this.$router.push('/home')
			// 				} else {
			// 					if (this.ruleForm.phone == 'test') {
			// 						this.$message.error('测试账号已过期')
			// 					} else {
			// 						this.$message.error(res.data.desc);
			// 					}
			// 				}
			// 			});
			// 		} else {
			// 			console.log('error submit!!');
			// 			return false;
			// 		}
			// 	});
			// },
			// getInfo() {
			// 	http.fetchGet("/api/user/info").then((res) => {
			// 		console.log('getInfo')
			// 		if (res.data.code == 0) {
			// 			this.$emit("onLogin")
			// 		}
			// 	});
			// },
		}
	}
</script>

<style lang="scss" scoped>
	@import '~@/assets/css/login.scss';
</style>
