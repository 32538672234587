<template>
	<div class="main-login">
		<div class="login-con">
			<img src="../../assets/img/login/login-title.png" class="title" />
			<div class="content">
				<el-carousel :interval="6000" class="carousel" height="5.4rem">
					<el-carousel-item v-for="item in carouselList" :key="item.id">
						<img class="banner-img" :src="item.img" />
					</el-carousel-item>
				</el-carousel>
				<div class="right">
					<img src="../../assets/img/login/login-logo.png" class="logo" />
					<div class="nav">
						<div class="nav-item" :class="activeIndex==index?'active':''" v-for="(item,index) in navList" :key="item.id"
							@click="navItem(index)">{{item.name}}</div>
					</div>
					<dealerLogin v-if="activeIndex==0"></dealerLogin>
					<insuranceLogin v-if="activeIndex==1"></insuranceLogin>
					<div class="login-footer">
						<p>玉韦耶克物联有限责任公司 技术支持 Copyright © {{year}} 版权所有 宝达集团</p>
						<p class="beian">
							<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鲁ICP备2022035994号-2</a>
							<a class="beian-item" target="_blank"
								href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37030602000461">鲁公网安备
								37030602000461号</a>
						</p>
					</div>
				</div>
			</div>
			<el-button type="text" @click="login" class="login-admin">管理登录</el-button>
		</div>
	</div>
</template>

<script>
	import dealerLogin from "./components/dealerLogin.vue";
	import insuranceLogin from "./components/insuranceLogin.vue";
	let myDate = new Date();
	let tYear = myDate.getFullYear();
	export default {
		name: "Home",
		components: {
			dealerLogin,
			insuranceLogin
		},
		data() {
			return {
				year: tYear,
				navList: [{
					id: 1,
					name: '经销商登录'
				}, {
					id: 2,
					name: '保险定损'
				}],
				activeIndex: 0,
				carouselList: [{
					img: require("../../assets/img/login/login-banner01.jpg")
				}, {
					img: require("../../assets/img/login/login-banner02.jpg")
				}, {
					img: require("../../assets/img/login/login-banner03.jpg")
				}, {
					img: require("../../assets/img/login/login-banner04.jpg")
				}]
			};
		},
		created() {},
		methods: {
			login() {
				this.$router.push('/login')
			},
			navItem(index) {
				this.activeIndex = index
			}

		},
	};
</script>


<style lang="scss" scoped>
	@import '~@/assets/css/login.scss';
</style>